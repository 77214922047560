.loader{
  display: inline-block;
  position: relative;
  height: 32px;
  width: 120px;
  border-bottom: 5px solid #000;
  box-sizing: border-box;
  animation: balancing 2s linear infinite alternate;
  transform-origin: 50% 100%;
}
.loader:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #FF3D00;
  animation: ballbns 2s linear infinite alternate;
}
.loader:after{
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 20px;
  width: 20px;
  transform: translate(-50%, 100%);
  border-radius: 50%;
  border: 6px solid #000;
}
@keyframes ballbns {
  0% {  left: 0; transform: translateX(0%); }
  100% {  left: 100%; transform: translateX(-100%); }
}
@keyframes balancing {
  0% {  transform: rotate(-15deg); }
  50% {  transform: rotate(0deg); }
  100% {  transform: rotate(15deg); }
}

.btn-purple {
  background: #2c4f40;
  border: #2c4f40;
  color: #ffffff;
}

.btn-purple2 {
  background: #250b59;
  border: #250b59;
  color: #ffffff;
}

.bg-purple3 {
  background: #250b59;
  border: #250b59;
  color: #ffffff;
}

.bg-purple {
  background: #2c4f40;
  border: #2c4f40;
  color: #ffffff;
}

.bg-purple2 {
  background: #c6c5ff;
  border: #c6c5ff;
  color: #ffffff;
}

.text-purple {
  color: #2c4f40;
}

.text-purple2 {
  color: #250b59;
}

.bg-back {
  background-color: #f6f6f6;
  background: #f6f6f6;
  border: #f6f6f6;
}

.bg-warning-50 {
  background: #fbe0c8;
  border: #fbe0c8;
}

.text-trans {
  color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(90, 76, 105);
  border-radius: 10px;
}

.fs-10 {
  font-size: 30px;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: -0.04rem;
}

.bg-terms {
  background: rgb(209,245,234);
  background: radial-gradient(circle, rgba(209,245,234,1) 28%, rgba(255,255,255,1) 99%); 
}

#name-input:valid { text-transform: lowercase; }